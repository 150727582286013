import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./Header.css";
import { Grid, ListItemIcon, Menu, MenuItem, TextField } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import MobileDrawer from "./MobileDrawer";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ClearIcon from "@mui/icons-material/Clear";
const Header = () => {
  // const { window } = props;
  const location = useLocation();
  const navigate = useNavigate();
  console.log("location", location.pathname);
  const [serveOpen, setServeOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [serviceAnchorEl, setServiceAnchorEl] = useState(null);
  const [useCaseAnchorEl, setUseCaseAnchorEl] = useState(null);
  const [caseStudiesAnchorEl, setCaseStudiesAnchorEl] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const serviceOpen = Boolean(serviceAnchorEl);
  const useCaseOpen = Boolean(useCaseAnchorEl);
  const caseStudiesOpen = Boolean(caseStudiesAnchorEl);
  const menuOpen = Boolean(menuAnchorEl);

  const checkServeOpen = () => {
    let serveMenus = [
      "/startup",
      "/small-and-medium-sized-business",
      "/enterprise",
    ];
    if (serveMenus.includes(location.pathname)) {
      setServeOpen(true);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const handleChange = () => {
    setServeOpen((prev) => !prev);
  };

  const MenuHandleClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const MenuHandleClose = () => {
    setServeOpen(false);
    setMenuAnchorEl(null);
  };
  const ServiceHandleClick = (event) => {
    setServiceAnchorEl(event.currentTarget);
  };
  const ServiceHandleClose = () => {
    setServiceAnchorEl(null);
  };
  const UseCaseHandleClick = (event) => {
    setUseCaseAnchorEl(event.currentTarget);
  };
  const UseCaseHandleClose = () => {
    setUseCaseAnchorEl(null);
  };
  const CaseStudiesHandleClick = (event) => {
    setCaseStudiesAnchorEl(event.currentTarget);
  };
  const CaseStudiesHandleClose = () => {
    setCaseStudiesAnchorEl(null);
  };

  const checkServiceMenuActive = () => {
    let services = [
      "/services/fintech-software-development",
      "/services/ewallet-app-development",
      "/services/p2p-development",
    ];
    if (services.includes(location.pathname)) {
      return true;
    }
  };
  const checkUseCasesMenuActive = () => {
    let services = [
      "/use-cases",
      "/use-cases/e-wallet",
      "/use-cases/general-ledger",
      "/use-cases/mobile-wallet",
      "/use-cases/money-transfer",
      "/use-cases/neobank",
      "/use-cases/payment-acceptance",
    ];
    if (services.includes(location.pathname)) {
      return true;
    }
  };
  const checkCaseStudiesMenuActive = () => {
    let services = [
      "/case-studies/payment-solution-provider",
      "/case-studies/digital-wallet-solution",
      "/case-studies/mobile-money-processing",
    ];
    if (services.includes(location.pathname)) {
      return true;
    }
  };
  const checkWhoWeServeMenuActive = () => {
    let services = [
      "/startup",
      "/small-and-medium-sized-business",
      "/enterprise",
    ];
    if (services.includes(location.pathname)) {
      return true;
    }
  };
  useEffect(() => {
    checkServeOpen();
  }, []);

  return (
    <>
      <div
        // style={{
        //   position: "sticky",
        //   top: 0,
        //   width: "100%",
        //   maxWidth: "2400px",
        //   boxSizing: "border-box",
        //   padding: "0 200px",
        //   margin: "auto",
        //   zIndex: 1000,
        // }}
        id="header"
        className="appbar_holder"
      >
        <AppBar position="sticky" className="appbar_style">
          <Toolbar style={{ paddingRight: 0, paddingLeft: 0 }}>
            <Box
              component="div"
              sx={{ flexGrow: 1, display: { xs: "block", md: "block" } }}
            >
              <Link to="/">
                <img
                  src="/images/logo.svg"
                  alt="the qrpay logo"
                  className="header_logo_style"
                />
              </Link>
            </Box>
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <MobileDrawer
                checkServiceMenuActive={checkServiceMenuActive}
                checkUseCasesMenuActive={checkUseCasesMenuActive}
                checkCaseStudiesMenuActive={checkCaseStudiesMenuActive}
                checkWhoWeServeMenuActive={checkWhoWeServeMenuActive}
              />
            </Box>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <Button
                component={Link}
                to="/"
                className={`nav_item ${location.pathname === "/" && "active"}`}
              >
                Home
              </Button>
              <Button
                component={Link}
                to="/platform"
                className={`nav_item ${
                  location.pathname === "/platform" && "active"
                }`}
              >
                Platform
              </Button>
              <Button
                className={`nav_item ${checkServiceMenuActive() && "active"}`}
                endIcon={<ArrowDropDownIcon />}
                onClick={ServiceHandleClick}
                aria-controls={serviceOpen ? "servcies" : undefined}
                aria-haspopup="true"
                aria-expanded={serviceOpen ? "true" : undefined}
              >
                Services
              </Button>
              <Menu
                anchorEl={serviceAnchorEl}
                id="services"
                open={serviceOpen}
                onClose={ServiceHandleClose}
                onClick={ServiceHandleClose}
                className="topbar_submenu"
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  className={`${
                    location.pathname ===
                      "/services/fintech-software-development" &&
                    "topbar_submenu_active"
                  }`}
                  onClick={() => {
                    ServiceHandleClose();
                    navigate("/services/fintech-software-development");
                  }}
                >
                  <ListItemIcon>
                    <img
                      src="/images/fintech-software-development.svg"
                      alt="fintech software development"
                    />
                  </ListItemIcon>
                  Fintech software development
                </MenuItem>
                <MenuItem
                  className={`${
                    location.pathname === "/services/ewallet-app-development" &&
                    "topbar_submenu_active"
                  }`}
                  onClick={() => {
                    ServiceHandleClose();
                    navigate("/services/ewallet-app-development");
                  }}
                >
                  <ListItemIcon>
                    <img
                      src="/images/eWallet-app-development.svg"
                      alt="eWallet app development"
                    />
                  </ListItemIcon>
                  eWallet app development
                </MenuItem>
                <MenuItem
                  className={`${
                    location.pathname === "/services/p2p-development" &&
                    "topbar_submenu_active"
                  }`}
                  onClick={() => {
                    ServiceHandleClose();
                    navigate("/services/p2p-development");
                  }}
                >
                  <ListItemIcon>
                    <img
                      src="/images/p2p-development.svg"
                      alt="P2P development"
                    />
                  </ListItemIcon>
                  P2P development
                </MenuItem>
              </Menu>
              <Button
                className={`nav_item ${checkUseCasesMenuActive() && "active"}`}
                endIcon={<ArrowDropDownIcon />}
                onClick={UseCaseHandleClick}
                aria-controls={useCaseOpen ? "use-cases" : undefined}
                aria-haspopup="true"
                aria-expanded={useCaseOpen ? "true" : undefined}
              >
                Use cases
              </Button>
              <Menu
                anchorEl={useCaseAnchorEl}
                id="use-cases"
                open={useCaseOpen}
                onClose={UseCaseHandleClose}
                onClick={UseCaseHandleClose}
                className="topbar_submenu"
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  className={`${
                    location.pathname === "/use-cases/e-wallet" &&
                    "topbar_submenu_active"
                  }`}
                  onClick={() => {
                    UseCaseHandleClose();
                    navigate("/use-cases/e-wallet");
                  }}
                >
                  <ListItemIcon>
                    <img
                      src="/images/eWallet-app-development.svg"
                      alt="eWallet"
                    />
                  </ListItemIcon>
                  eWallet
                </MenuItem>
                <MenuItem
                  className={`${
                    location.pathname === "/use-cases/general-ledger" &&
                    "topbar_submenu_active"
                  }`}
                  onClick={() => {
                    UseCaseHandleClose();
                    navigate("/use-cases/general-ledger");
                  }}
                >
                  <ListItemIcon>
                    <img
                      src="/images/Contacts-Paper2.svg"
                      alt="General ledger"
                    />
                  </ListItemIcon>
                  General ledger
                </MenuItem>
                <MenuItem
                  className={`${
                    location.pathname === "/use-cases/mobile-wallet" &&
                    "topbar_submenu_active"
                  }`}
                  onClick={() => {
                    UseCaseHandleClose();
                    navigate("/use-cases/mobile-wallet");
                  }}
                >
                  <ListItemIcon>
                    <img src="/images/Wallet-Mobile2.svg" alt="Mobile wallet" />
                  </ListItemIcon>
                  Mobile wallet
                </MenuItem>
                <MenuItem
                  className={`${
                    location.pathname === "/use-cases/money-transfer" &&
                    "topbar_submenu_active"
                  }`}
                  onClick={() => {
                    UseCaseHandleClose();
                    navigate("/use-cases/money-transfer");
                  }}
                >
                  <ListItemIcon>
                    <img
                      src="/images/Money-transfer2.svg"
                      alt="Money transfer"
                    />
                  </ListItemIcon>
                  Money transfer
                </MenuItem>
                <MenuItem
                  className={`${
                    location.pathname === "/use-cases/neobank" &&
                    "topbar_submenu_active"
                  }`}
                  onClick={() => {
                    UseCaseHandleClose();
                    navigate("/use-cases/neobank");
                  }}
                >
                  <ListItemIcon>
                    <img src="/images/Cradit-deposit2.svg" alt="Neobank" />
                  </ListItemIcon>
                  Neobank
                </MenuItem>
                <MenuItem
                  className={`${
                    location.pathname === "/use-cases/payment-acceptance" &&
                    "topbar_submenu_active"
                  }`}
                  onClick={() => {
                    UseCaseHandleClose();
                    navigate("/use-cases/payment-acceptance");
                  }}
                >
                  <ListItemIcon>
                    <img src="/images/Managed2.svg" alt="Payment acceptance" />
                  </ListItemIcon>
                  Payment acceptance
                </MenuItem>
              </Menu>
              <Button
                endIcon={<ArrowDropDownIcon />}
                className={`nav_item ${
                  checkCaseStudiesMenuActive() && "active"
                }`}
                onClick={CaseStudiesHandleClick}
                aria-controls={caseStudiesOpen ? " case-studies" : undefined}
                aria-haspopup="true"
                aria-expanded={caseStudiesOpen ? "true" : undefined}
              >
                Case studies
              </Button>
              <Menu
                anchorEl={caseStudiesAnchorEl}
                id=" case-studies"
                open={caseStudiesOpen}
                onClose={CaseStudiesHandleClose}
                onClick={CaseStudiesHandleClose}
                className="topbar_submenu"
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  className={`${
                    location.pathname ===
                      "/case-studies/payment-solution-provider" &&
                    "topbar_submenu_active"
                  }`}
                  onClick={() => {
                    CaseStudiesHandleClose();
                    navigate("/case-studies/payment-solution-provider");
                  }}
                >
                  <ListItemIcon>
                    <img
                      src="/images/Money-payment2.svg"
                      alt="Payment solution provider"
                    />
                  </ListItemIcon>
                  Payment solution provider
                </MenuItem>
                <MenuItem
                  className={`${
                    location.pathname ===
                      "/case-studies/digital-wallet-solution" &&
                    "topbar_submenu_active"
                  }`}
                  onClick={() => {
                    CaseStudiesHandleClose();
                    navigate("/case-studies/digital-wallet-solution");
                  }}
                >
                  <ListItemIcon>
                    <img
                      src="/images/Wallet-manage2.svg"
                      alt="Digital wallet system"
                    />
                  </ListItemIcon>
                  Digital wallet system
                </MenuItem>
                <MenuItem
                  className={`${
                    location.pathname ===
                      "/case-studies/mobile-money-processing" &&
                    "topbar_submenu_active"
                  }`}
                  onClick={() => {
                    CaseStudiesHandleClose();
                    navigate("/case-studies/mobile-money-processing");
                  }}
                >
                  <ListItemIcon>
                    <img
                      src="/images/Wallet-Mobile2.svg"
                      alt="Mobile money processing"
                    />
                  </ListItemIcon>
                  Mobile money processing
                </MenuItem>
              </Menu>
              <IconButton
                style={{ marginRight: "16px" }}
                onClick={MenuHandleClick}
                aria-controls={menuOpen ? "menu" : undefined}
                aria-haspopup="true"
                aria-expanded={menuOpen ? "true" : undefined}
              >
                <MenuIcon style={{ color: "#303030" }} />
              </IconButton>
              <Menu
                anchorEl={menuAnchorEl}
                id="menu"
                open={menuOpen}
                onClose={MenuHandleClose}
                // onClick={MenuHandleClose}
                className="topbar_submenu"
                PaperProps={{
                  elevation: 0,

                  sx: {
                    minWidth: 240,
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  onClick={handleChange}
                  style={{ background: serveOpen && "#f3f3f3" }}
                >
                  <ListItemIcon>
                    <img src="/images/who-we-serve.svg" alt="Who we serve" />
                  </ListItemIcon>
                  Who we serve
                  {serveOpen ? (
                    <ExpandLess className="list_item_icon_style" />
                  ) : (
                    <ExpandMore className="list_item_icon_style" />
                  )}
                </MenuItem>
                <Collapse in={serveOpen} timeout="auto" unmountOnExit>
                  <div
                    className={`topbar_submenu_menu ${
                      location.pathname === "/startup" &&
                      "topbar_submenu_active"
                    }`}
                    onClick={() => {
                      MenuHandleClose();
                      navigate("/startup");
                    }}
                  >
                    <div>
                      <FiberManualRecordIcon style={{ fontSize: "10px" }} />
                    </div>
                    <div>Start up</div>
                  </div>
                  <div
                    className={`topbar_submenu_menu ${
                      location.pathname ===
                        "/small-and-medium-sized-business" &&
                      "topbar_submenu_active"
                    }`}
                    onClick={() => {
                      MenuHandleClose();
                      navigate("/small-and-medium-sized-business");
                    }}
                  >
                    <div>
                      <FiberManualRecordIcon style={{ fontSize: "10px" }} />
                    </div>
                    <div>Small & medium sized business</div>
                  </div>
                  <div
                    className={`topbar_submenu_menu ${
                      location.pathname === "/enterprise" &&
                      "topbar_submenu_active"
                    }`}
                    onClick={() => {
                      MenuHandleClose();
                      navigate("/enterprise");
                    }}
                  >
                    <div>
                      <FiberManualRecordIcon style={{ fontSize: "10px" }} />
                    </div>
                    <div>Enterprise</div>
                  </div>
                </Collapse>
                <MenuItem
                  className={`${
                    location.pathname === "/about-us" && "topbar_submenu_active"
                  }`}
                  onClick={() => {
                    MenuHandleClose();
                    navigate("/about-us");
                  }}
                >
                  <ListItemIcon>
                    <img src="/images/About_us_icon.svg" alt="About us" />
                  </ListItemIcon>
                  About us
                </MenuItem>
              </Menu>
              <Button
                className="nav_button"
                endIcon={<img src="/images/qrpayicon.svg" alt="qrpay icon" />}
                onClick={handleClickOpen}
              >
                Request A demo
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
          className="demo_dialog"
        >
          <DialogContent style={{ padding: "0px" }}>
            <Grid container style={{ maxWidth: "1050px" }}>
              <Grid
                item
                md={5.5}
                className="demo_dialog_left  hideForTabViewOnly hideForMobileViewOnly"
              >
                <p
                  className="demo_form_title center"
                  style={{ marginTop: "114px" }}
                >
                  Request a Demo?
                </p>
                <p className="text_body_medium center">
                  Fill it up. We will contact you
                </p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6.5}
                style={{ padding: "50px 40px 40px 40px", position: "relative" }}
              >
                <IconButton
                  onClick={handleClose}
                  className="demo_form_close_button"
                >
                  <ClearIcon />
                </IconButton>
                <Grid container>
                  <Grid item xs={12}>
                    <p className="demo_form_title center showForTabAndMobileViewOnly">
                      Request a Demo?
                    </p>
                    <p className="text_body_medium showForTabAndMobileViewOnly center">
                      Fill it up. We will contact you
                    </p>

                    <p className="text_body_medium mt16">Name*</p>
                    <TextField
                      fullWidth
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                      className="demo_form_input_style"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <p className="text_body_medium mt16">Email Address*</p>
                    <TextField
                      fullWidth
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                      className="demo_form_input_style"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <p className="text_body_medium mt16">Company</p>
                    <TextField
                      fullWidth
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                      className="demo_form_input_style"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <p className="text_body_medium mt16">Message*</p>
                    <TextField
                      fullWidth
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                      className="demo_form_textarea_style"
                      multiline
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12} className="center mt25">
                    <Button
                      variant="contained"
                      color="primary"
                      // endIcon={<ArrowForwardIcon />}
                      className="contained_buttton"
                      style={{ minWidth: "250px" }}
                      disableElevation
                    >
                      Send
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
        </Dialog>
      </div>
    </>
  );
};

export default Header;
