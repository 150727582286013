import React from "react";
import "./Footer.css";
import { Container, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import MySlider from "./MySlider";
import MessageForm from "./MessageForm";
// console.log("year", new Date().getFullYear());
const Footer = () => {
  return (
    <>
      <Container maxWidth="lg">
        <MessageForm />
      </Container>
      {/* <MySlider /> */}
      <footer style={{ background: "#E2EEFF" }}>
        <Container maxWidth="lg" style={{ padding: "56px 24px" }}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12} lg={3.5}>
              <Link to="/">
                <img
                  src="/images/logo.svg"
                  alt="the qrpay logo"
                  className="footer_logo_style"
                />
              </Link>
              <p
                className="footer_item"
                style={{ marginTop: "13px", fontWeight: 400 }}
              >
                Our API-driven SaaS platform is a revolutionary way to build
                digital banking and payment products quickly, without hefty
                upfront investments, saving time and accelerating release.
              </p>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={2.5}>
              <p className="footer_title">Company</p>
              <ul className="footer_item">
                <li>
                  <Link to="/platform" className="link_style">
                    Platform
                  </Link>
                </li>
                <li>
                  <Link to="/startup" className="link_style">
                    Who we serve
                  </Link>
                </li>
                <li>
                  <Link to="/about-us" className="link_style">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/terms-and-conditions" className="link_style">
                    Terms & Conditions
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={3}>
              <p className="footer_title">Others</p>
              <ul className="footer_item">
                <li>
                  <Link to="/services/p2p-development" className="link_style">
                    P2P Development
                  </Link>
                </li>
                <li>
                  <Link
                    to="/case-studies/payment-solution-provider"
                    className="link_style"
                  >
                    Payment solution provider
                  </Link>
                </li>
                <li>
                  <Link
                    to="/case-studies/digital-wallet-solution"
                    className="link_style"
                  >
                    Digital wallet system
                  </Link>
                </li>
                <li>
                  <Link
                    to="/case-studies/mobile-money-processing"
                    className="link_style"
                  >
                    Mobile money processing
                  </Link>
                </li>
                <li>
                  <Link
                    to="/use-cases/payment-acceptance"
                    className="link_style"
                  >
                    Payment acceptance
                  </Link>
                </li>
              </ul>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={3}>
              <address>
                <p className="footer_title">Contact Us</p>
                <Grid container>
                  <Grid item xs={2}>
                    <img src="/images/email.svg" alt="email" />
                  </Grid>
                  <Grid item xs={10}>
                    <p className="footer_item2">
                      <Link
                        to="mailto:info@theqrpay.com"
                        className="link_style"
                      >
                        info@theqrpay.com
                      </Link>
                    </p>
                    <p className="footer_item2">
                      <Link
                        to="mailto:suport@theqrpay.com"
                        className="link_style"
                      >
                        suport@theqrpay.com
                      </Link>
                    </p>
                  </Grid>
                </Grid>
                {/* <Grid container>
                  <Grid item xs={2}>
                    <img src="/images/phone.svg" alt="phone" />
                  </Grid>
                  <Grid item xs={10}>
                    <p className="footer_item2">(406) 555-0120</p>
                    <p className="footer_item2">(704) 555-0127</p>
                  </Grid>
                </Grid> */}
                {/* <Grid container>
                <Grid item xs={2}>
                  <img src="/images/location.svg" alt="" />
                </Grid>
                <Grid item xs={10}>
                  <p className="footer_item">
                    Lorem ipsum dolor sit amet consectetur. Amet proin viverra
                    imperdiet
                  </p>
                </Grid>
              </Grid> */}
                {/* <Grid container>
                <img
                  src="/images/facebookIcon.svg"
                  alt="facebook icon"
                  style={{ marginRight: "10px" }}
                />
                <img
                  src="/images/instagramIcon.svg"
                  alt="instagram icon"
                  style={{ marginRight: "10px" }}
                />
                <img
                  src="/images/twitterIcon.svg"
                  alt="twitter icon"
                  style={{ marginRight: "10px" }}
                />
                <img src="/images/linkedinIcon.svg" alt="linkedin icon" />
              </Grid> */}
              </address>
            </Grid>
          </Grid>
        </Container>
        <div className="footer_bottom_section_holder">
          <Container maxWidth="lg" style={{ padding: "0px 0px" }}>
            <Grid container>
              <Grid item xs={12} sm={6} md={6}>
                <p className="footer_text_style">
                  &copy; Copyright {new Date().getFullYear()} QRP, Inc.
                </p>
                <p className="footer_text_style showForMobileViewOnly">
                  All Rights Reserved.
                </p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                className="hideForMobileViewOnly"
              >
                <p className="footer_text_style" style={{ textAlign: "right" }}>
                  All Rights Reserved.
                </p>
              </Grid>
            </Grid>
          </Container>
        </div>
      </footer>
    </>
  );
};

export default Footer;
